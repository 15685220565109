'use strict';

let menuMouseObserver = null;

function Menu() {
    this.currentMenuItem = null;
    this.mainNavigation = null;
    this.navToggle = null;
}

Menu.prototype.initialize = function() {
    this.mainNavigation = $( '#nav_main' );
    this.navToggle = $( '#nav_toggle' );

    this.bindEvents();
};

Menu.prototype.bindEvents = function() {
    const self = this;

    self.mainNavigation.on( 'click mouseenter mouseleave', '> ul > li > a', function( e ) {
        self.currentMenuItem = $( this ).parent( 'li' );

        if ( self.currentMenuItem.hasClass( 'menu-item-has-children' ) && 'click' === e.type ) {

            //e.preventDefault();
        }

        if ( ! self.mobileMenuActive() ) {
            if ( 'mouseenter' === e.type ) {
                self.mainNavigation.find( '> ul > li' ).not( self.currentMenuItem ).removeClass( 'open' );

                self.disableTimeout();

                self.currentMenuItem.toggleClass( 'open', true );
            } else if ( 'mouseleave' === e.type ) {
                self.enableTimeout();
            }
        } else {
            if ( 'click' === e.type ) {
                self.currentMenuItem.toggleClass( 'open' );
                e.preventDefault();
            }
        }
    });

    self.mainNavigation.on( 'mouseenter mouseleave', '> ul > li > ul', function( e ) {
        self.currentMenuItem = $( this ).parent( 'li' );

        if ( ! self.mobileMenuActive() ) {
            if ( 'mouseenter' === e.type ) {
                self.disableTimeout();
            } else {
                self.enableTimeout();
            }
        }
    });

    self.navToggle.on( 'click', function() {
        const body = $( 'body' );
        const header = $( '#header' );

        body.toggleClass( 'nav-toggle-active' );
    });
};

Menu.prototype.enableTimeout = function() {
    const self = this;

    menuMouseObserver = setTimeout( function() {
        self.currentMenuItem.toggleClass( 'open', false );
    }, 500 );
};

Menu.prototype.disableTimeout = function() {
    clearTimeout( menuMouseObserver );
};

Menu.prototype.mobileMenuActive = function() {
    return $( 'body' ).hasClass( 'nav-toggle-active' );
};

module.exports = new Menu();
