'use strict';

require( '../scss/app.scss' );
require( '../img/logo.svg' );
require( '../img/groot-hoogwaak-logo.svg' );
require( '../img/logo-dia.svg' );
require( '../img/footer-background.png' );
require( '../img/vinger-print-half.png' );

const menu = require( './shared/menu' );

/**
 * Groothoogwaak class
 *
 * @constructor
 */
function Groothoogwaak() {
    this.initMenu();
}

/**
 * Initializes the main menu
 */
Groothoogwaak.prototype.initMenu = function() {
    menu.initialize();
};

$( function() {
    new Groothoogwaak();
});

// $(document).ready(function(){
//     $("#nav_toggle").click(function(){
//         //alert("test");
//         $("nav.nav-main").toggle();
//     });
// });
